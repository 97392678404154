import { ScrollableListProps } from './scrollable-list.props';

export const ScrollableListPresets: Partial<ScrollableListProps> = {
  itemsPerPage: 1,
  gapVisible: false,
  itemsCount: 0,
  hasHorizontalPadding: false,
  mode: 'page',
  nonActiveOpacity: 1,
  activePage: 0,
};
