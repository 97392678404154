import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { ScrollableListProps } from './scrollable-list.props';
import { BoxContainer } from 'layout/box-container';
import { StyledScrollableList } from './scrollable-list.styles';
import { ScrollableListPresets } from './scrollable-list.presets';
import { animated, useSprings } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import useResizeObserver from 'use-resize-observer/polyfilled';
import { extractValueFromResolutionAwarePropertyForCurrentScreenSize } from 'helpers/layout.helpers';
import { useMediaLarge } from 'utils/hooks';
import { useEnsuredForwardedRef } from 'react-use';

export const ScrollableList = forwardRef(
  (props: ScrollableListProps, ref: any) => {
    const {
      renderItem,
      itemsCount,
      itemsPerPage,
      gapVisible,
      gap,
      padding,
      hasHorizontalPadding,
      hasRightPadding = hasHorizontalPadding,
      hasLeftPadding = hasHorizontalPadding,
      mode,
      nonActiveOpacity,
      activePage,
      onPageChange,
      onListScroll,
    } = Object.assign({}, ScrollableListPresets, props);

    const containerRef = useEnsuredForwardedRef(ref);

    const [innerItemsPerPage, setInnerItemsPerPage] = useState(0);
    const [currentGapValue, setCurrentGapValue] = useState(0);

    const firstItemRef = useRef<HTMLDivElement>();
    const index = useRef(0);
    const [currentPage, setCurrentPage] = useState(0);
    const large = useMediaLarge();

    const [animationProps, setAnimationProps] = useSprings(
      itemsCount,
      position => ({
        x: position * (firstItemRef.current?.clientWidth ?? 0),
        opacity: currentPage === position ? 1 : nonActiveOpacity,
      }),
      [],
    );

    useEffect(() => {
      setAnimationProps((position: number) => {
        return {
          x: 0,
          opacity: activePage === position ? 1 : nonActiveOpacity,
        };
      });
    }, [itemsCount, large]);

    useEffect(() => {
      if (activePage !== currentPage) {
        const greaterAllowedPage =
          index.current === itemsCount - 2
            ? itemsCount - innerItemsPerPage
            : Math.floor(itemsCount - innerItemsPerPage);

        index.current = Math.min(greaterAllowedPage, activePage);

        setCurrentPage(activePage);

        if (onPageChange) {
          onPageChange(activePage);
        }

        const nextGapSum = index.current * currentGapValue;

        setTimeout(() => {
          containerRef.current.scrollTo(0, 0);
        }, 300);

        setAnimationProps((position: number) => {
          return {
            x:
              (index.current * firstItemRef.current.clientWidth + nextGapSum) *
              -1,
            opacity: activePage === position ? 1 : nonActiveOpacity,
          };
        });
      }
    }, [activePage]);

    useResizeObserver({
      ref: containerRef,
      onResize: () => {
        const containerStyles = window.getComputedStyle(containerRef.current);
        const gap = parseFloat(containerStyles.columnGap);
        setCurrentGapValue(gap || 0);

        setInnerItemsPerPage(
          extractValueFromResolutionAwarePropertyForCurrentScreenSize(
            itemsPerPage,
          ),
        );
      },
    });

    useEffect(() => {
      if (onListScroll) {
        const total = Math.ceil(itemsCount - innerItemsPerPage);

        onListScroll({
          percentage: (currentPage * 100) / total,
          totalPages: total,
        });
      }
    }, [innerItemsPerPage, currentPage, itemsCount, onListScroll]);

    const bind = useDrag(
      async ({ down, movement: [mx], distance, direction: [mxDir] }) => {
        if (innerItemsPerPage > itemsCount) {
          return false;
        }

        const gapSum = index.current * currentGapValue;
        const pageDistance =
          (index.current * firstItemRef.current.clientWidth + gapSum) * -1;

        if (down) {
          containerRef.current.style.pointerEvents = 'none';
        } else {
          containerRef.current.style.pointerEvents = 'all';
        }

        if (!down) {
          let nextPageDistance = pageDistance;

          if (distance > 100) {
            let greaterAllowedPage = Math.floor(itemsCount - innerItemsPerPage);

            if (index.current + innerItemsPerPage > itemsCount - 2) {
              const decimalPart =
                innerItemsPerPage - Math.floor(innerItemsPerPage);
              greaterAllowedPage += decimalPart;
            }

            index.current =
              mxDir < 0
                ? Math.min(greaterAllowedPage, index.current + 1)
                : Math.max(0, Math.ceil(index.current - 1));

            const nextGapSum = index.current * currentGapValue;

            nextPageDistance =
              (index.current * firstItemRef.current.clientWidth + nextGapSum) *
              -1;

            setTimeout(() => {
              containerRef.current.scrollTo(0, 0);
            }, 300);

            setCurrentPage(Math.ceil(index.current));

            if (onPageChange) {
              onPageChange(Math.ceil(index.current));
            }
          }

          setAnimationProps((position: number) => {
            return {
              x: nextPageDistance,
              opacity:
                position === Math.ceil(index.current) ? 1 : nonActiveOpacity,
            };
          });
        } else {
          setAnimationProps(() => {
            return {
              x: pageDistance + mx,
            };
          });
        }
      },
      {
        axis: 'x',
        lockDirection: true,
      },
    );

    return (
      <BoxContainer horizontalPadding={padding} maxWidth="var(--vw)">
        <StyledScrollableList
          ref={containerRef}
          itemsPerPage={itemsPerPage}
          gapVisible={gapVisible}
          gap={gap}
          hasRightPadding={hasRightPadding}
          hasLeftPadding={hasLeftPadding}
          {...(mode === 'page' || (mode === 'scroll' && large) ? bind() : {})}>
          {animationProps.map((animatedStyles, position: number) => (
            <animated.div
              key={position}
              style={animatedStyles}
              ref={position === 0 ? firstItemRef : null}>
              {renderItem(position)}
            </animated.div>
          ))}
          {mode === 'scroll' && hasRightPadding && (
            <div style={{ width: '1px' }} />
          )}
        </StyledScrollableList>
      </BoxContainer>
    );
  },
);
