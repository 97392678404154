import { TabsProps } from './tabs.props';

export const TabsPresets: TabsProps = {
  config: [],
  selectedTab: 0,
  headingWidth: 'minmax(0, 1fr)',
  headingAlign: 'flex-start',
  headingGap: 0,
  headingColor: 'grey',
  headingActiveColor: 'black',
  headerBorderVisible: true,
  headingTypographyProps: {},
};
