import React, { useEffect, useRef, useState } from 'react';
import { TabConfig, TabsProps } from './tabs.props';
import { TabsPresets } from './tabs.presets';
import {
  StyledSelectedTabIndicator,
  StyledTabHeading,
  StyledTabsContainer,
  StyledTabsContent,
  StyledTabsContentItem,
  StyledTabsHeadingContainer,
} from 'core/tabs/tabs.styles';
import { Typography } from 'core/typography';
import { useSpring, useSprings } from 'react-spring';
import useResizeObserver from 'use-resize-observer/polyfilled';
import * as easings from 'd3-ease';
import { trackAnalyticsEvent } from 'utils/analytics';
import { extractValueFromResolutionAwarePropertyForCurrentScreenSize } from 'helpers/layout.helpers';
import sum from 'lodash/sum';

export const Tabs = (props: TabsProps) => {
  const {
    config,
    selectedTab,
    headingWidth,
    headingAlign,
    headingGap,
    headingColor,
    headingActiveColor,
    headerBorderVisible,
    headingTypographyProps,
    onTabSelected,
  } = Object.assign({}, TabsPresets, props);

  const [active, setActive] = useState(0);
  const [indicatorWidth, setIndicatorWidth] = useState(0);
  const [xGap, setXGap] = useState(0);
  const currentGap = useRef(0);

  useEffect(() => {
    setActive(selectedTab);
  }, [selectedTab]);

  const headingRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const indicatorShownRef = useRef(false);

  useEffect(() => {
    setTimeout(() => {
      setXGap(headingRef.current?.offsetLeft || 0);
    }, 200);
  }, [headingRef.current?.offsetLeft]);

  // @ts-ignore
  const [{ x, opacity: indicatorOpacity }, set] = useSpring(() => {
    const firstHeadingX = headingRef.current?.offsetLeft || 0;

    const headingsWidthSum = sum(
      config.slice(0, active).map(item => item.width || indicatorWidth),
    );

    return {
      to: async next => {
        await next({
          x: headingsWidthSum + firstHeadingX + currentGap.current * active,
          opacity: 1,
        });

        indicatorShownRef.current = true;
      },
      from: {
        x: 0,
        opacity: indicatorShownRef.current ? 1 : 0,
      },
      config: {
        duration: indicatorShownRef.current ? 150 : 0,
        easing: easings.easeCubicInOut,
      },
    };
  }, [
    active,
    indicatorWidth,
    headingRef.current,
    xGap,
    indicatorShownRef.current,
  ]);

  useResizeObserver({
    ref: containerRef,
    onResize: () => {
      const width = headingRef.current?.offsetWidth || 0;
      setIndicatorWidth(width);

      currentGap.current = extractValueFromResolutionAwarePropertyForCurrentScreenSize(
        headingGap,
      );

      // @ts-ignore
      set({ x: active * width + headingRef.current?.offsetLeft || 0 });
    },
  });

  const [springs, setSprings] = useSprings(
    config.length,
    index => ({
      opacity: active === index ? 1 : 0,
      scale: active === index ? 1 : 0,
    }),
    [active],
  );

  const setSelectedTab = index => () => {
    if (active !== index) {
      setActive(index);

      if (config[index].width) {
        setIndicatorWidth(config[index].width);
      }

      setSprings(idx => {
        return {
          opacity: index === idx ? 1 : 0,
          scale: index === idx ? 1 : 0,
          config: {
            tension: 500,
          },
        };
      });

      if (onTabSelected) {
        onTabSelected(index);
      }

      if (config[index]?.analyticsEvent) {
        trackAnalyticsEvent(config[index]?.analyticsEvent);
      }
    }
  };

  return (
    <StyledTabsContainer>
      <StyledTabsHeadingContainer
        ref={containerRef}
        headingAlign={headingAlign}
        headingGap={headingGap}
        headingWidth={headingWidth}
        headerBorderVisible={headerBorderVisible}>
        {config.map((item: TabConfig, index: number) => (
          <StyledTabHeading
            key={item.headingTx || item.heading}
            ref={index === 0 ? headingRef : null}
            size={item.width || headingWidth}
            onClick={setSelectedTab(index)}>
            <Typography
              tx={item.headingTx}
              text={item.heading}
              variant="h5"
              color={active === index ? headingActiveColor : headingColor}
              {...headingTypographyProps}
            />
          </StyledTabHeading>
        ))}

        <StyledSelectedTabIndicator
          style={{
            width: indicatorWidth,
            x,
            opacity: indicatorOpacity,
            backgroundColor: headingActiveColor,
          }}
        />
      </StyledTabsHeadingContainer>

      <StyledTabsContent>
        {springs.map(({ opacity }, index) => (
          <StyledTabsContentItem
            key={index}
            style={{
              opacity,
              position: active === index ? 'relative' : 'absolute',
              zIndex: active === index ? 2 : 1,
            }}>
            {config[index].content}
          </StyledTabsContentItem>
        ))}
      </StyledTabsContent>
    </StyledTabsContainer>
  );
};
