import styled, { css } from 'styled-components';
import { ExtractStyleFromResolutionAwarePropEntry } from 'types/layout';
import { generateStylesForResolutionAwareProps } from 'helpers/layout.helpers';
import { AppSize } from 'types/resolution-aware-prop.type';
import { getThemeSpacing } from 'styles/theme';
import { media } from 'styles/media';
import { animated } from '@react-spring/web';

export const StyledScrollableList = styled.div<{
  itemsPerPage;
  gapVisible;
  gap;
  hasRightPadding;
  hasLeftPadding;
}>`
  ${({
    itemsPerPage,
    gapVisible,
    gap,
    hasRightPadding,
    hasLeftPadding,
    theme,
  }) => {
    const entries: ExtractStyleFromResolutionAwarePropEntry[] = [
      {
        cssProperty: 'grid-auto-columns',
        resolutionAwareProp: [itemsPerPage, gapVisible],
        valueFormatter: (value, size) => {
          const [itemsCount, showGap] = value;
          const gapWidth = showGap ? `${size === 'lg' ? 1.5 : 0.6}` : 0;
          return `calc(100% / ${itemsCount} - 5px - ${gapWidth}rem)`;
        },
      },
      {
        cssProperty: 'column-gap',
        resolutionAwareProp: [gapVisible, gap],
        valueFormatter: (value, size: AppSize) => {
          const [showGap, gapAmount] = value;

          if (showGap) {
            if (gapAmount) {
              return getThemeSpacing(gapAmount);
            }

            return `${size === 'lg' ? theme.spacing.s3 : theme.spacing.s1}`;
          }

          return 'unset';
        },
      },
      {
        cssProperty: 'padding-left',
        resolutionAwareProp: hasLeftPadding,
        valueFormatter: (value, size: AppSize) =>
          value
            ? `${size === 'lg' ? theme.spacing.s3 : theme.spacing.s1}`
            : 'unset',
      },
      {
        cssProperty: 'padding-right',
        resolutionAwareProp: hasRightPadding,
        valueFormatter: (value, size: AppSize) =>
          value
            ? `${size === 'lg' ? theme.spacing.s3 : theme.spacing.s1}`
            : 'unset',
      },
    ];

    return css`
      display: grid;
      grid-auto-flow: column;
      overflow-x: hidden;
      max-width: 100%;
      //scrollbar-width: none;
      user-select: none;
      ${generateStylesForResolutionAwareProps(entries)};
      position: relative;
      touch-action: pan-y;
      cursor: pointer;

      ${media.lessThan('md')`
         touch-action: unset;
         overflow-x: auto;
      `}

      &::-webkit-scrollbar {
        display: none;
      }
    `;
  }}
`;
